import { useEffect, useState } from 'react';
import { Roboto } from 'next/font/google';
import Script from 'next/script';
import type { AppContext, AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { setAppData, store } from 'store';
import { AuthUser, CurrentUser } from 'types';
import { getFbAppId } from 'utils/seoFormatters';
import { getCookies, getSignUpSteps, openMobileApp } from 'utils/services';
import { processSessionError, processSessionSuccess } from 'utils/localStoreTools';
import { RequestsCacheProvider } from 'api/requestsCacheProvider';
import { CACHE, PERMISSION_LIST, SIGN_UP_STEP_CAPTIONS } from 'consts';
import { getAuthUser, getRegisterUser } from 'api';
import { Snackbar, SnackbarProps } from 'components/Snackbar';

import 'theme/globals.scss';

const roboto = Roboto({
  weight: ['400', '500', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
});

globalThis.cacheProvider = new RequestsCacheProvider(CACHE.default);

function StoreApp({ Component, pageProps }: AppProps) {
  const { renderStepper, user, passedSteps, currentSignUpStep, query } = pageProps as any;
  store.dispatch(setAppData({ renderStepper, user, passedSteps, currentSignUpStep }));
  const [sessionMessage, setSessionMessage] = useState<SnackbarProps | null>(null);

  useEffect(() => {
    if (query.app_link) {
      openMobileApp(`${query.app_link}`);
    }
  }, [query.app_link]);

  useEffect(() => {
    (window as any).fbAsyncInit = () => {
      window.FB.init({
        appId: getFbAppId(),
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v15.0',
      });
    };
    processSessionError(setSessionMessage);
    processSessionSuccess(setSessionMessage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <main className={roboto.className}>
      <Provider store={store}>
        {/* @ts-ignore */}
        <Component {...pageProps} />
        {sessionMessage && <Snackbar {...sessionMessage} onClose={() => setSessionMessage(null)} />}
      </Provider>
      <Script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />
      <script
        src="https://www.chatbase.co/embed.min.js"
        chatbotId="goZv4rdLTlegRfTviUHa5"
        domain="www.chatbase.co"
        defer>
      </script>
    </main>
  );
}

StoreApp.getInitialProps = async (context: AppContext) => {
  const { ctx } = context;
  const { req } = ctx;
  const userAgent = req?.headers['user-agent'] || '';
  const isMobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
  const cookies = { parsed: getCookies(req) };
  const user: AuthUser = (await getAuthUser(cookies)) || {};
  const registerUser: CurrentUser = user && user.registration_step ? await getRegisterUser(cookies) : {};

  if (user && user.role === 'super_admin' && !user.permissions?.length) {
    user.permissions = PERMISSION_LIST;
  }

  if (registerUser && Object.keys(registerUser).length) {
    const signUpSteps = getSignUpSteps(registerUser);
    if (signUpSteps?.passed.length < SIGN_UP_STEP_CAPTIONS.length) {
      registerUser.signUpSteps = signUpSteps;
    }
  }

  const filters = cookies?.parsed?.filters ? JSON.parse(cookies.parsed.filters) : {};

  return {
    pageProps: {
      renderStepper: false,
      user: { ...user, ...registerUser },
      query: context.router.query,
      initialFilters: filters,
      isMobile,
      userAgent,
    },
  };
};

export default StoreApp;
